function Contactpage() {
    return (
     <div className="container-fluid">
        <h1>
             Contact Page
        </h1>
        <p>
            Formulario de contacto
        </p>
         
     </div>
    );
  }
  
  export default Contactpage;
import { Route } from "wouter"

// Components
import Header from './components/headerComponent/header'
import Footer from './components/footerComponent/footer'
import Homepage from './components/pages/homePage'
import Contactpage from './components/pages/contactPage'

//Includes
import './assets/css/bundle.css'

function App() {
  return (
    <div className="App">

      <Header />
        <Route path="/" component={Homepage}/>
        <Route path="/contacto" component={Contactpage}/>
      <Footer />

    </div>
  );
}

export default App;
